.lol-modal {
  .modal-body {
    position: relative;
    .close {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
  .modal-content {
    &.alert-danger {
      color: #000;
      background-color: #ffcccc;
      border-color: #ffb8b8;
    }
  }
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;

  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.content-slide {
  padding: 0;
  margin-bottom: 2rem;
  padding: 0;
  display: block;

  white-space: nowrap;
  text-align: center;
  background: linear-gradient(transparent 60%, #f1f1f1 60%);

  li {
    a {
      display: inline-block;
      margin: 0 0.5rem 0.5rem 0.5rem;
      border: 3px solid transparent;
      width: 100%;

      &:hover {
        border-color: $primary;
      }

      img {
        max-width: 100%;
        object-fit: contain;
        width: 100%;
        aspect-ratio: 7 / 3;
        box-sizing: content-box;
      }
    }
  }

  .slide-dots {
    position: absolute;
    bottom: 1rem;
    left: 0;
    display: none;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;

      margin-right: 1rem;
      margin-left: 1rem;
      cursor: pointer;

      button {
        display: block;
        width: 16px;
        height: 16px;
        position: relative;
        text-indent: -9999px;
        border-radius: 50%;
        opacity: 1 !important;
        background: #fff;
        border: 1px solid #cccccc;
      }

      &.slick-active {
        button {
          border: 1px solid #cccccc;
          background: $iconcolor;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 0 0 2rem 0;

    li {
      padding: 0 0.5rem;
    }
  }
}

$primary: #cc0000;
$dark: #000000;
$iconcolor: #d71921;

$text-link: #b60005;

//header
$header-btn-cart: #b01116;
$header-btn-search: $primary;
$header-menu-background: #e6e7e8;

//footer
$footer-banner: #bcbdc0;
$footer-color: #333333;

//sidenavi
$category-icon: #cc0000;

//button
$btn-font-color: #4c4948;
$disabled-color: #bcbdc0;

//carousel
$carousel-active-color: $primary;

//button
$btn-next-color: #b01116;

//hdg color
$hdg-color:$iconcolor;
//logo size
$logo_width_sp: 180px;
$logo_width_sm: 240px;
$logo_width_md:300px;

@import "core";


.box-success-light {
  background-color: #f1f1f1;
  color: #000;
}

.button {
  background: #FFFFFF;
  border: 3px #cc0000 solid;
}

.btn-copy {
  background: #ffa8a8;
  color: $btn-font-color;
}

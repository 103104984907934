aside {
  margin-bottom: 2rem;
  @include media-breakpoint-down(md) {
    margin-top: 3rem;
  }
  .btn-regist {
    background: $primary;
    border-radius: 0.5rem;
    display: block;
    color: #fff;
    padding: 1rem;
    margin-bottom: 2rem;
    span {
      display: block;
      &.sub {
        text-align: center;
        font-size: 0.857rem;
      }
      &.main {
        text-align: center;
        font-size: 1.429rem;

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem 0 0;
        &:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f303";
          background: #fff;
          font-size: 1rem;
          height: 2rem;
          width: 2rem;
          line-height: 1;
          display: inline-block;
          color: $iconcolor;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.5rem;
        }
      }
    }
    &:hover {
      text-decoration: none;
      background: lighten($primary, 5%);
    }
  }
  .aside-item {
    a {
      padding: 0.5rem 1rem;
      display: block;
      color: #000;
    }
    .collapse-link {
      border-bottom: 1px solid rgb(227, 227, 227);
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background: #f1f1f1;
        text-decoration: none;
      }
      &:after {
        margin-left: 0.5em;
        font-size: 1.25em;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f055";
        color: $category-icon;
      }
      &[aria-expanded="false"] {
        &:after {
          content: "\f055";
        }
      }
      &[aria-expanded="true"] {
        &:after {
          content: "\f056";
        }
        background: #f1f1f1;
      }
    }

    .aside-item__child {
      margin-left: 1rem;
    }
  }
  .aside-banner {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-up(lg) {
      display: block;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      width: 100%;
    }
    & > div {
      margin: 0;
      display: block;
      width: 100%;
      text-align: center;
      @include media-breakpoint-up(md) {
      }
      @include media-breakpoint-up(lg) {
        width: 100%;
      }
      a {
        display: inline-block;
      }
      img {
        display: block;
        max-width: 100%;
        border: 3px solid transparent;
      }
      &:hover {
        img {
          border-color: $primary;
        }
      }
    }
  }
}

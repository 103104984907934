.l-pagination {
  margin-top: 3rem;
  &.is-filter {
    margin-top: 0;
  }
  .l-pagination-info {
    background-color: #eee;
    border-top: 1px solid #ccc;
    padding: 20px;
    text-align: center;

    span {
      font-weight: 700;
    }
    &.l-pagination-top {
      text-align: left;
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .view-control {
      margin-top: 1rem;
      @include media-breakpoint-up(md) {
        margin-top: 0;
        padding-left: 1em;
        margin-left: 1em;
        border-left: 1px solid #ccc;
      }
      display: flex;
      .view-control__title {
        margin-right: 0.5em;
      }
      a {
        margin: 0 0.2rem;
        background: #fff;
        border-radius: 0.3rem;
        padding: 0.5rem;
        line-height: 1;
        color: #000;
        &.active {
          color: #2678b3;
        }
        &:hover {
          background: lighten(#2678b3, 50%);
        }
      }
    }
  }
  .l-pageination-link {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    margin-top: 1rem;
    .l-page-list {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;
      @include media-breakpoint-down(sm) {
        width: 100%;
        flex-wrap: wrap;
      }
      .l-page-list__link {
        margin: 0.25rem;

        a {
          display: block;
          padding: 1em 0.75em;

          border: 1px solid #ccc;
          line-height: 1;
          color: #000;
          font-size: 0.85rem;
          &:hover {
            text-decoration: none;
            background: #f2f2f2;
          }
        }
        &.active {
          span {
            line-height: 1;
            display: block;
            padding: 1em 0.75em;
            font-weight: bold;
            background: #eee;
            border: 1px solid #f2f2f2;
            font-size: 0.85rem;
          }
        }
      }
    }
    .l-page__prev,
    .l-page__next {
      @include media-breakpoint-down(sm) {
        width: 45%;
      }
      text-align: center;
      margin: 0.25rem;
      a {
        display: block;
        padding: 1em 0.75em;
        border: 1px solid #ccc;
        line-height: 1;
        color: #000;
        font-size: 0.85rem;
        &:hover {
          text-decoration: none;
          background: #f2f2f2;
        }
      }
    }
    .l-page__prev {
      @include media-breakpoint-down(sm) {
        order: 2;
      }
      a {
        &:before {
          color: $primary;
          display: inline-block;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f104";
          margin-right: 0.4rem;
        }
      }
    }
    .l-page__next {
      @include media-breakpoint-down(sm) {
        order: 3;
      }
      a {
        &:after {
          color: $primary;
          display: inline-block;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f105";
          margin-left: 0.4rem;
        }
      }
    }
  }
}

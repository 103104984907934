footer {
  margin-top: 3rem;
  a {
    color: #fff;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
  p,
  ul {
    margin: 0;
    padding: 0;
  }
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      a {
        margin: 0.5rem 1rem;
        display: block;
      }
    }
  }
  .bg-color01,
  .bg-color02 {
    margin: 0;
  }
  .bg-color01 {
    background-color: $footer-banner;
  }
  .bg-color02 {
    background-color: $footer-color;
  }
  .footer-nav {
    padding: 1rem;
  }
  .footer-banner {
    @include media-breakpoint-up(md) {
      display: flex;
    }
    align-items: center;
    h1 {
      background: #fff;
      font-size: 1rem;
      color: $iconcolor;
      padding: 1rem;
      margin-right: 2rem;
      @include media-breakpoint-down(sm) {
        display: inline-block;
      }
    }
  }
  .list-add-copyright {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    a {
      color: #cccccc;
    }

    .copyright {
      color: #cccccc;
      font-size: 0.857rem;
      width: 100%;
      text-align: center;
      margin-top: 1rem;
      margin-right: -1rem;
    }
  }
}

@media screen and (min-width: 960px) {
  .aside section.__block:not(:last-child) {
    margin-bottom: 1.5em;
  }
}
.aside img {
  max-width: 100%;
  height: auto;
}

.aside section.__block.__block--cart .__body,
.aside section.__block.__block--search .__body {
  padding: 1em;
}

.aside section.__block.__block--login .__body {
  padding: 1em 0.5em;
}
.aside section.__block.__block--login .__body ul.__menu {
  list-style-type: none;
  padding: 0;
  margin: 0.5em 0 0 0;
}
.aside section.__block.__block--login .__body ul.__menu li {
  display: block;
  text-align: center;
  padding: 0.15em 0;
}
.aside section.__block.__block--login .__body ul.__menu li a:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\F0DA";
  margin-right: 0.3em;
}

.aside section.__block.__block--login.__is-guest .__body .__login-link {
  text-align: center;
  padding: 0.5em 0;
}
.aside section.__block.__block--login.__is-guest .__body .__login-link .__link {
  color: #fff;
  background-color: #d9534f;
  font-weight: bold;
  padding-left: 3.5em;
  padding-right: 3.5em;
  border-radius: 2em;
}

.aside section.__block.__block--login.__is-guest .__body .__login-form dl {
  margin: 0.3em 0;
  text-align: center;
  letter-spacing: -0.5em;
}
.aside section.__block.__block--login.__is-guest .__body .__login-form dl dt,
.aside section.__block.__block--login.__is-guest .__body .__login-form dl dd {
  letter-spacing: normal;
  display: inline-block;
}
.aside section.__block.__block--login.__is-guest .__body .__login-form dl dt {
  width: 45%;
  max-width: 8em;
  padding-right: 0.5em;
  text-align: right;
}
.aside section.__block.__block--login.__is-guest .__body .__login-form dl dd {
  width: 55%;
  max-width: 12em;
  margin: 0;
}
.aside section.__block.__block--login.__is-guest .__body .__login-form dl dd input {
  width: 100%;
}

.aside section.__block.__block--login.__is-guest .__body .__login-form .__submit {
  text-align: center;
  padding: 0.5em 0;
}
.aside section.__block.__block--login.__is-guest .__body .__login-form .__submit .__button {
  background-color: #d9534f;
  color: #fff;
  padding-left: 3.5em;
  padding-right: 3.5em;
  border-radius: 2em;
  font-weight: bold;
}

.aside section.__block.__block--login.__is-member .__message .__name {
  display: inline;
  margin-right: 0.5em;
}
.aside section.__block.__block--login.__is-member .__message .__name span {
  margin-right: 0.3em;
}

.aside section.__block.__block--login.__is-member .__message .__group {
  display: inline-block;
}
.aside section.__block.__block--login.__is-member .__message .__group span {
  display: inline-block;
  font-size: 0.8em;
  padding: 0 0.5em;
  vertical-align: middle;
  border: 1px solid #ccc;
  background-color: #fff;
}

.aside section.__block.__block--login.__is-member .__point {
  font-size: 0.85em;
}
.aside section.__block.__block--login.__is-member .__point dl {
  margin: 0.5em 0 0 0;
}
.aside section.__block.__block--login.__is-member .__point dl dt,
.aside section.__block.__block--login.__is-member .__point dl dd {
  display: inline-block;
}
.aside section.__block.__block--login.__is-member .__point dl dt:after {
  content: ":";
  margin-right: 0.3em;
}
.aside section.__block.__block--login.__is-member .__point dl dd {
  margin: 0;
}

.aside section.__block.__block--cart .__body .__total {
  text-align: center;
  padding: 0.5em 0;
}
.aside section.__block.__block--cart .__body .__total .__count {
  display: inline-block;
}
.aside section.__block.__block--cart .__body .__total .__count span.__num {
  font-size: 1.15em;
  font-weight: bold;
  margin-right: 2px;
}
.aside section.__block.__block--cart .__body .__total dl.__price {
  display: inline-block;
  margin: 0 0 0 1em;
}
.aside section.__block.__block--cart .__body .__total dl.__price dt,
.aside section.__block.__block--cart .__body .__total dl.__price dd {
  display: inline-block;
}
.aside section.__block.__block--cart .__body .__total dl.__price dt {
  margin-right: 2px;
}
.aside section.__block.__block--cart .__body .__total dl.__price dd {
  margin: 0;
  font-size: 1.15em;
  font-weight: bold;
}

.aside section.__block.__block--cart .__body .__to-cart {
  text-align: center;
  margin-top: 0.5em;
}
.aside section.__block.__block--cart .__body .__to-cart a:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\F0DA";
  margin-right: 0.3em;
}

.aside section.__block.__block--cart.__is-empty .__body {
  text-align: center;
}

.aside section.__block.__block--search .__body {
  text-align: center;
}
@media (min-width: 960px) {
  .aside section.__block.__block--search .__body input {
    width: 7em;
  }
}

.aside section.__block.__block--calendar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.aside section.__block.__block--calendar ul:before,
.aside section.__block.__block--calendar ul:after {
  content: " ";
  display: table;
}
.aside section.__block.__block--calendar ul:after {
  clear: both;
}
.aside section.__block.__block--calendar ul li {
  padding: 0 1em;
  margin: 1em 0;
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .aside section.__block.__block--calendar ul li {
    float: left;
    width: 50%;
  }
}
.aside section.__block.__block--calendar ul li table.cal {
  width: 100%;
}
.aside section.__block.__block--calendar ul li table.cal thead th {
  text-align: center;
  padding: 0.5em 0;
}
.aside section.__block.__block--calendar ul li table.cal tbody th,
.aside section.__block.__block--calendar ul li table.cal tbody td {
  text-align: center;
  width: 14.27%;
}
.aside section.__block.__block--calendar ul li table.cal tbody th {
  background-color: #f2f2f2;
}
.aside section.__block.__block--calendar ul li table.cal tbody td {
  padding: 0;
  border: 1px solid #fff;
}
.aside section.__block.__block--calendar ul li table.cal tbody td span {
  display: block;
  width: 100%;
  height: 100%;
}
.aside section.__block.__block--calendar ul li table.cal tbody td span.cal-week-close {
  background-color: #ddd;
}

.aside section.__block.__block--category .__body ul.__tree {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.aside section.__block.__block--category .__body ul.__tree li.__item {
  display: block;
  position: relative;
}
.aside section.__block.__block--category .__body ul.__tree li.__item a {
  border-top: 1px dotted #e6e6e6;
  display: block;
  text-decoration: none;
}
@media screen and (min-width: 960px) {
  .aside section.__block.__block--category .__body ul.__tree li.__item a {
    padding: 0.3em 0;
  }
}
@media screen and (max-width: 959px) and (min-width: 600px) {
  .aside section.__block.__block--category .__body ul.__tree li.__item a {
    padding: 0.5em 0 0.5em 1em;
  }
}
@media screen and (max-width: 599px) {
  .aside section.__block.__block--category .__body ul.__tree li.__item a {
    padding: 0.75em 0 0.75em 1em;
  }
}
.aside section.__block.__block--category .__body ul.__tree li.__item ul {
  padding-left: 1em;
}
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children > a {
  padding-right: 3em;
}
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children span.__js-toggle {
  display: inline-block;
  position: absolute;
  text-align: center;
  right: 0;
  top: 1px;
  padding: 0.3em 0;
  width: 2em;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;
}
@media screen and (max-width: 959px) {
  .aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children span.__js-toggle {
    padding: 0.5em 0;
    width: 3em;
  }
}
@media screen and (max-width: 599px) {
  .aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children span.__js-toggle {
    padding: 0.75em 0;
  }
}
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children span.__js-toggle:before {
  font-size: 1.5em;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  content: "\E5C5";
  vertical-align: middle;
}
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children ul {
  display: none;
}
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--img > a {
  padding: 0;
  border-bottom: none;
}
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--img > a img {
  vertical-align: middle;
}
.aside section.__block.__block--category .__body ul.__tree > li.__item {
  border-top: 1px solid #e6e6e6;
}
@media screen and (min-width: 960px) {
  .aside section.__block.__block--category .__body ul.__tree > li.__item {
    margin-top: 0.5em;
  }
  .aside section.__block.__block--category .__body ul.__tree > li.__item:first-child {
    border-top: none;
    margin-top: 0;
  }
}
@media screen and (max-width: 959px) {
  .aside section.__block.__block--category .__body ul.__tree > li.__item:last-child {
    border-bottom: 1px solid #e6e6e6;
  }
}
.aside section.__block.__block--category .__body ul.__tree > li.__item > a {
  border-top: none;
}
@media screen and (min-width: 960px) {
  .aside section.__block.__block--category .__body ul.__tree > li.__item > a {
    padding: 0.5em 0;
  }
}
.aside section.__block.__block--category .__body ul.__tree > li.__item:first-child > a {
  border-top: none;
}
.aside section.__block.__block--category .__body ul.__tree > li.__item.__item--has-children > span.__js-toggle {
  display: none;
}
@media screen and (max-width: 959px) {
  .aside section.__block.__block--category .__body ul.__tree > li.__item.__item--has-children > span.__js-toggle {
    display: inline-block;
  }
}
.aside section.__block.__block--category .__body ul.__tree > li.__item.__item--has-children > ul {
  display: block;
}
@media screen and (max-width: 959px) {
  .aside section.__block.__block--category .__body ul.__tree > li.__item.__item--has-children > ul {
    display: none;
  }
}

.aside section.__block.__block--feature .__body ul,
.aside section.__block.__block--page .__body ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 959px) {
  .aside section.__block.__block--feature .__body ul,
  .aside section.__block.__block--page .__body ul {
    border-top: 1px solid #e6e6e6;
  }
}
.aside section.__block.__block--feature .__body ul li.__item,
.aside section.__block.__block--page .__body ul li.__item {
  display: block;
  border-bottom: 1px solid #e6e6e6;
}
@media screen and (min-width: 960px) {
  .aside section.__block.__block--feature .__body ul li.__item:last-child,
  .aside section.__block.__block--page .__body ul li.__item:last-child {
    border-bottom: none;
  }
}
.aside section.__block.__block--feature .__body ul li.__item a,
.aside section.__block.__block--page .__body ul li.__item a {
  display: block;
  padding: 0.5em 0;
}
.aside section.__block.__block--feature .__body ul li.__item.__item--img > a,
.aside section.__block.__block--page .__body ul li.__item.__item--img > a {
  padding: 0;
  border-bottom: none;
}
.aside section.__block.__block--feature .__body ul li.__item.__item--img > a img,
.aside section.__block.__block--page .__body ul li.__item.__item--img > a img {
  vertical-align: middle;
}
@media screen and (max-width: 959px) {
  .aside section.__block.__block--feature .__body ul li.__item a:hover,
  .aside section.__block.__block--page .__body ul li.__item a:hover {
    text-decoration: none;
  }
  .aside section.__block.__block--feature .__body ul li.__item.__item--text a,
  .aside section.__block.__block--page .__body ul li.__item.__item--text a {
    position: relative;
    padding: 0.75em 1.5em 0.75em 1em;
  }
  .aside section.__block.__block--feature .__body ul li.__item.__item--text a:after,
  .aside section.__block.__block--page .__body ul li.__item.__item--text a:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\F054";
    position: absolute;
    right: 1em;
    top: 50%;
    margin-top: -0.5em;
    color: #ccc;
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .aside section.__block.__block--feature .__body ul,
  .aside section.__block.__block--page .__body ul {
    letter-spacing: -0.5em;
  }
  .aside section.__block.__block--feature .__body ul li.__item,
  .aside section.__block.__block--page .__body ul li.__item {
    letter-spacing: normal;
    display: inline-block;
    width: 50%;
  }
  .aside section.__block.__block--feature .__body ul li.__item:nth-child(odd),
  .aside section.__block.__block--page .__body ul li.__item:nth-child(odd) {
    border-right: 1px solid #e6e6e6;
  }
}
@media screen and (max-width: 599px) {
  .aside section.__block.__block--feature .__body ul li.__item a:after,
  .aside section.__block.__block--page .__body ul li.__item a:after {
    right: 1em;
  }
}

@media screen and (max-width: 959px) {
  .aside section.__block.__block--free.__is-text {
    padding-right: 1em;
    padding-left: 1em;
  }
}

.aside section.__block.__block--paid {
  text-align: center;
}

/*-----------------------------------------------------------------
rv_custom.css 結合
-------------------------------------------------------------------*/

/*-----------------------------------------------------------------
	default
-------------------------------------------------------------------*/
/**
 * リンク
 */

.over,
a,
input[type="button"],
input[type="submit"],
button,
.accordion dt,
.main--mypage .__button,
.p-product-block ul.__product li.__item a .__photo img,
.p-product-block ul.__product li.__item a .__photo.__is-noimage .__noimage .__wrap,
.c-button,
.c-button-submit,
.main.main--mypage-order-list section.__list table.__table tbody tr td.__detail a img,
.main.main--mypage-estimate-list section.__list table.__table tbody tr td.__detail a img,
.main.main--product-detail section.__information .__primary .__photo .__sub ul li a img,
.main.main--mypage-estimate-detail
  section.__detail
  .__delivery
  .__product
  table
  tbody
  tr
  td.__description
  a
  .__image
  img,
.main.main--order-form section.__block.__block--payment .__body table tr td > ul > li .__description a img,
.main.main--mypage-setting section.__input table tr.__row-payment td > ul > li .__description a img,
.main.main--product-list section.__list.__list--row ul.__product li.__item a table tr td.__photo img,
.main.main--product-list
  section.__list.__list--row
  ul.__product
  li.__item
  a
  table
  tr
  td.__photo.__is-noimage
  .__noimage
  .__wrap,
.main.main--mypage-order-list section.__list table.__table tbody tr td.__detail a .__image img,
.main.main--mypage-estimate-list section.__list table.__table tbody tr td.__detail a .__image img,
.p-cart .__product table tbody td.__description a .__image img,
.main.main--order-finish section.__estimate-pdf a,
.pagetop span:before,
.btn[target*="_blank"]:hover,
.mktoForm .mktoButtonWrap.mktoInset .mktoButton,
.jconfirm .jconfirm-box .jconfirm-content a {
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

.__to-home,
.p-cart .__add-bookmark,
.slider > li,
.content-slide,
.__limited-free-3,
.__pagetop,
.p-product-block ul.__product li.__item a .__rank span.__icon span.__num,
.aside section.__block .__heading.__heading--default,
.aside section.__block .__heading.__heading--custom,
.aside section.__block.__block--category .__body ul.__tree > li.__item.__item--has-children > ul {
  display: none;
}

/*-----------------------------------------------------------------
	parts
-------------------------------------------------------------------*/

.underline,
.underline-over:hover,
.__more a:hover,
.__link a:hover,
.p-mypage-navi nav.__menu .__body ul li a:hover,
.main.main--regist-finish section.__message .txt a:hover,
.header-btn .list-btn-group .btn-group a:hover,
.main.main--mypage-order-list section.__list table.__table tbody tr td.__detail a:hover .__name,
.main.main--mypage-estimate-list section.__list table.__table tbody tr td.__detail a:hover .__name,
.main.main--mypage-estimate-detail section.__detail .__delivery .__product table tbody td.__description a:hover .__name,
.list-inline-item a:hover,
.list-footer-item li a:hover,
.footer-site-menu li a:hover,
.p-product-block ul.__product li.__item a:hover .__title,
.important-notices dd a:hover,
.main.main--home section.__block.__block--news .__body ul li a:hover span.__title,
.c-more a:hover,
.aside section.__block.__block--category .__body ul.__tree li.__item a:hover,
.p-menu-login ul li a:hover,
a[target*="_blank"]:hover,
.__link a:hover,
.main.main--regist-finish section.__message .txt a:hover,
.main.main--news-detail section.__to-list a:hover,
.list-anchor .list-inline-item a:hover span {
  text-decoration: underline;
}

/*-----------------------------------------------------------------
	icon
-------------------------------------------------------------------*/
/**
 * アイコン 共通
 */
.icon-contact:before,
a[target*="_blank"]:not(img):after,
.icon-search:before,
.icon-angle-right:after,
.icon-accordion:after,
.icon-window:after,
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children span.__js-toggle:before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

/**
 * アコーディオンアイコン（閉）
 */
.icon-accordion:after,
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children span.__js-toggle:before {
  content: "\f055";
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  color: $iconcolor;
}

.icon-accordion:after,
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children span.__js-toggle:before {
  font-size: 18px;
  font-size: 1.3rem;
  line-height: 2rem;
}

.icon-accordion:after {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/**
 * アコーディオンアイコン（開）
 */
.open.icon-accordion:after,
.aside
  section.__block.__block--category
  .__body
  ul.__tree
  li.__item.__item--has-children
  span.__js-toggle.__is-open:before {
  content: "\f056";
}

/*-----------------------------------------------------------------
	color
-------------------------------------------------------------------*/

.c-heading-main,
.__button.c-button-submit,
.__button.c-button,
.__link.c-button,
.main.main--quickorder-form .__csv-form .__heading,
.main.main--quickorder-form .__input-form .__heading,
.main.main--home section.__block.__block--news .__body ul li a span.__date,
.accordion dt,
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children > a,
.main.main--product-detail section.__information .__secondary .__description,
.p-product-set table tbody tr td.__description .__spec,
.main.main--login section.__to-order .__link,
.p-form-table tr td p.__example,
.main.main--order-finish section.__estimate-pdf a,
.btn[target*="_blank"]:hover,
.p-pagination .__link .__next a:before,
.p-pagination .__link .__prev a:after {
  color: #000;
}

/*-----------------------------------------------------------------
	sidebar
-------------------------------------------------------------------*/

/**
 * accordion
 */
.accordion dt,
.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children > a {
  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;
  position: relative;
  line-height: 1.2em;
}

.aside section.__block.__block--category .__body ul.__tree li.__item ul {
  padding-left: 10px;
}

.aside section.__block.__block--category .__body ul.__tree li.__item a {
  padding: 10px 30px 10px 0;
  color: #555;
  font-size: 13px;
  font-size: 0.9rem;
}

.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children > a {
  padding: 13px 30px 13px 10px;
}

.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children > a {
  display: block;
  font-size: 14px;
  font-size: 1rem;
  padding: 12px 30px 12px 10px;
}

.aside section.__block.__block--category .__body ul.__tree > li.__item,
.aside section.__block.__block--category .__body ul.__tree li.__item a {
  border-top: 0;
}

.aside section.__block.__block--category .__body ul.__tree > li.__item.__item--has-children > span.__js-toggle,
.aside section.__block.__block--category .__body ul.__tree > li.__item:first-child > ul {
  display: block;
}

.aside section.__block.__block--category .__body ul.__tree > li.__item:last-child {
  border-bottom: 0;
}

.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children > a.__is-active {
  background-color: #f1f1f1;
}

.aside section.__block.__block--category .__body ul.__tree li.__item.__item--has-children span.__js-toggle {
  padding: 0;
  top: 0;
  height: 37px;
  width: 30px;
  line-height: 35px;
}

/*---------------------------------------------
header
---------------------------------------------*/

header {
  position: relative;
  z-index: 21;

  .btn-regist,
  .btn-login,
  .btn-cart {
    padding: 1rem 1rem;
    line-height: 1;
    justify-content: center;
    margin: 0;

    &:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin-right: 0.5rem;
    }

    @include media-breakpoint-up(xl) {
      min-width: 9em;
    }
  }

  .btn-regist {
    background: $primary;
    color: #fff;
    width: 14em;
    position: relative;

    &:before {
      content: "\f303";
    }

    &:hover {
      background: darken($primary, 10%);
      color: #fff;
    }

    &+.dropdown-menu {
      min-width: 13rem;
      width: auto;
      padding: 0;

      .dropdown-item {
        padding: 0.5rem 1.5rem;
      }
    }

    &.dropdown-toggle {
      padding-right: 4rem;

      &:after {
        position: absolute;
        right: 0;
        top: 0;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f0d7";
        border: none;
        width: 3rem;
        height: 3rem;
        border-left: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .show .btn-regist.dropdown-toggle {
    &:after {
      content: "\f0d8";
    }
  }

  .btn-login {
    background: #fff;
    color: $primary;
    border: 1px solid $primary;

    &:before {
      content: "\f007";
    }

    &:hover {
      background: darken($primary, 10%);
      color: #fff;
    }
  }

  .btn-cart {
    background: $header-btn-cart;
    color: #fff;
    margin-left: 1rem;

    &:before {
      content: "\f07a";
    }

    &:hover {
      background: darken($header-btn-cart, 10%);
      color: #fff;
    }

    @include media-breakpoint-down(md) {
      display: block;
      margin: 1rem 1rem;

      span {
        display: block;
        font-size: 8px;
      }
    }
  }

  .header-top {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 2rem;

    .btn-regist {
      display: none;

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }
  }

  .nav-header-wrap {
    @include media-breakpoint-up(lg) {
      position: relative;
    }
  }

  .head-top-nav {
    @include media-breakpoint-up(lg) {
      position: absolute;
      right: 0;
      top: -5rem;
    }
  }

  .loggin-user {
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 0 auto;
    margin-right: 2rem;
    display: none;

    div {
      text-align: right;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  nav {
    width: 100%;
    background: $header-menu-background;
  }

  .navbar-brand {
    margin: 0.5rem 0;

    img {
      max-width: $logo_width_sp;
      max-height: 50px;

      @include media-breakpoint-up(sm) {
        max-width: $logo_width_sm;
      }

      @include media-breakpoint-up(md) {
        max-width: $logo_width_md;
      }
    }
  }

  .navbar-toggler {
    position: absolute;
    top: 1.3rem;
    right: 0.5rem;
    background-color: transparent;
    border: none;
    color: #555;
    font-size: 14px;
    cursor: pointer;
    z-index: 1041;

    &:focus {
      outline: none;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .header-input {
    display: flex;
    align-items: center;
    height: 100%;

    form {
      width: 100%;
    }

    .header-input-group {
      display: flex;
      align-items: stretch;
      height: 100%;
      justify-content: space-between;
      padding: 2rem 1rem;

      .form-control {
        border-radius: 0.25rem 0 0 0.25rem;
      }

      .header-input-group-btn {
        button {
          background: $header-btn-search;
          color: #fff;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

          &:hover {
            opacity: 0.65;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        padding: 0 2rem 0 1rem;
      }
    }
  }

  .nav-menus {
    width: 80%;
    height: 100vh;
    top: 100%;
    right: 0;
    position: absolute;
    background-color: #efefef;
    z-index: 1040;
    transition: transform 0.3s cubic-bezier(0, 0.94, 0.5, 0.99);
    transform: translateX(100%);

    &.open {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      /* Account for horizontal padding on navbar */
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
      height: auto;
      padding: 1rem 0;
      position: inherit;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      /* Account for horizontal padding on navbar */
      overflow-x: inherit;
      background: none;
      display: flex;
    }

    .loggin-user {
      display: block;
      margin: 2rem 1rem 0;

      div {
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .btn-regist {
      display: none;
      width: calc(100% - 3rem);
      margin: 1rem auto;

      @include media-breakpoint-down(md) {
        display: block;
      }

      &+.dropdown-menu {
        min-width: 13rem;
        width: calc(100% - 3rem);
      }
    }
  }

  .nav-menu {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      &>li:hover,
      &>li.current {
        &>a {
          opacity: 0.35;
        }
      }

      &>li:hover {
        .dropdown-menu {
          display: block;
        }
      }
    }

    li {
      display: inline-block;
      width: 100%;
      border-top: solid 1px #000;
      position: relative;

      @include media-breakpoint-up(lg) {
        width: inherit;
        border: none;
        text-align: center;
      }

      a.gnavlink {
        height: 100%;
        width: 100%;
        padding: 1rem 30px 1rem 15px;
        display: block;
        background: #fff;
        text-decoration: none;
        color: #000;
        font-size: 1rem;
        transition: color 0.3s, background 0.3s;
        line-height: 1;

        @include media-breakpoint-up(lg) {
          display: flex;
          background: none;
          align-items: center;
          position: relative;
        }

        @include media-breakpoint-up(lg) {
          justify-content: center;
          padding: 0.5em 12px;

          &:hover,
          &.current {
            &:before {
              opacity: 1;
            }
          }
        }
      }

      &.focus {
        a {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

body {
  &.open {
    height: 100vh;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 5rem;
  bottom: 0;
  z-index: 20;
  transition: 0.3s ease-in-out;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}
